import React from "react";
import {
  SEO,
  PageLayout,
  MarkdownContent,
  MultipleComponentIterator,
  SlideReveal,
  BackgroundImageTeamMember,
  InternalLink,
} from "@bluefin/components";
import { Grid, Header, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class TeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
          ogUrl={"https://www.hairitisri.com/team/"}
        />
        <PageLayout hero={false} className={"team-page"}>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"team-content-container"}
          >
            <Grid.Column width={16}>
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "stylists_header",
                  defaultValue: "Our Team",
                })}
              />
              <MarkdownContent
                className={"about-team-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "stylists_description",
                })}
              />
              <Card.Group itemsPerRow={3} className={"stylists"}>
                <MultipleComponentIterator
                  iterator={allFishermanBusinessTeam.nodes}
                  components={[
                    {
                      component: (
                        <SlideReveal
                          className={"ui card team-member"}
                          fade={true}
                          direction={"up"}
                          triggerOnce={true}
                        />
                      ),
                      propMap: { key: "_id" },
                      children: [
                        {
                          component: (
                            <BackgroundImageTeamMember
                              useModal={false}
                              as={InternalLink}
                            />
                          ),
                          propMap: {
                            "teamMember.name": "name",
                            "teamMember.role": "role",
                            "teamMember.photo": "gatsbyImage",
                            "teamMember.description": "description",
                            "teamMember.email": "email",
                            "teamMember.phone": "phone",
                            "teamMember.locations": "locations",
                            "teamMember.hours": "hours",
                            "teamMember.social": "socialMedia",
                            to: createTeamMemberSlug,
                          },
                        },
                      ],
                    },
                  ]}
                />
              </Card.Group>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Team" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
